import renderHydrogen from '@shopify/hydrogen/entry-client';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import {config} from './config';

if (config.sentryEnabled) {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: config.sentryEnvironment,
    integrations: [new BrowserTracing()],
  });
}

function ClientApp({children}) {
  return children;
}

export default renderHydrogen(ClientApp);
